const options = [
  {
    menuid: 1,
    key: "tenants",
    label: "Tenant",
    leftIcon: "tentantbuttonBg",
    role: "OloidSysAdmin",
  },
  // {
  //   menuid: 1,
  //   key: "dashboard",
  //   label: "Dashboard",
  //   leftIcon: "dashboardbuttonBg",
  //   role: "OloidSysAdmin",
  // },
  {
    menuid: 1,
    key: "apiStatus",
    label: "API Status",
    leftIcon: "apiStatusbuttonBg",
    role: "OloidSysAdmin",
  },
  // {
  //   menuid: 1,
  //   key: "report",
  //   label: "Report",
  //   leftIcon: "reportbuttonBg",
  //   role: "OloidSysAdmin",
  // },
  {
    menuid: 1,
    key: "supportTenant",
    label: "Support Tenant",
    role: "Support",
    leftIcon: "reportbuttonBg",
  },
  {
    menuid: 1,
    key: "supportUser",
    label: "Support Team",
    leftIcon: "support",
    role: "OloidSysAdmin",
  },
];

export default options;
