import { userConstants } from "../constants";
import { userService } from "../service";
import _ from "lodash";

export const fetchUserSuccess = (response) => ({
  type: userConstants.GET_ALL_USER_SUCCESS,
  payload: response,
});
export const fetchUserDetailSuccess = (response) => ({
  type: userConstants.GET_USER_DETAIL_SUCCESS,
  payload: response,
});

export const copyUserSuccess = (response) => ({
  type: userConstants.GET_ALL_USER_COPY_SUCCESS,
  payload: response,
});

export const fetchUserFailure = (response) => ({
  type: userConstants.GET_ALL_USER_FAILURE,
  payload: response,
});
export const fetchUserDetailFailure = (response) => ({
  type: userConstants.GET_USER_DETAIL_FAILURE,
  payload: response,
});

export const getSupportUsers = () => {
  return (dispatch) => {
    return userService.getAllUsers().then(
      (response) => {
        let res = _.orderBy(response, ["UserCreateDate"], ["desc"]);
        let newObj = res?.map((data) => {
          let obj = {
            UserCreatedDateTime: data?.UserCreateDate,
            UserEmail: data.Attributes?.find((item) => item.Name === "email")
              .Value,
            UserDisplayName: data.Attributes?.find(
              (item) => item.Name === "name"
            ).Value,
            UserStatus: data?.Enabled ? "Active" : "Inactive",
            UserAccessLevel: data.Attributes?.find(
              (item) => item.Name === "custom:access_policy"
            ).Value?.replace(/,/g, ", "),
            UserRole: data.Attributes?.find(
              (item) => item.Name === "custom:role"
            ).Value,
            UserUpdatedDateTime: data?.UserLastModifiedDate,
            UserName: data?.Username,
            EmployeeID: data.Attributes?.find(
              (item) => item.Name === "custom:employee_id"
            ).Value,
            UserPhone: data.Attributes?.find(
              (item) => item.Name === "phone_number"
            ).Value,
          };
          return obj;
        });

        dispatch(fetchUserSuccess(newObj));
        dispatch(copyUserSuccess(newObj));
        return newObj;
      },
      (error) => {
        dispatch(fetchUserFailure(error));
        return error;
      }
    );
  };
};
export const getSupportUserByID = (id) => {
  return (dispatch) => {
    return userService
      .getUsersById(id)
      .then(
        (response) => {
          let obj = {
            UserCreatedDateTime: response?.UserCreateDate,
            UserEmail: response.UserAttributes?.find(
              (item) => item.Name === "email"
            ).Value,
            UserDisplayName: response.UserAttributes?.find(
              (item) => item.Name === "name"
            ).Value,
            UserStatus: response?.Enabled ? "enable" : "disable",

            UserAccessLevel: response.UserAttributes?.find(
              (item) => item.Name === "custom:access_policy"
            ).Value,
            UserUpdatedDateTime: response?.UserLastModifiedDate,
            UserName: response?.Username,
            EmployeeID: response.UserAttributes?.find(
              (item) => item.Name === "custom:employee_id"
            ).Value,
            UserPhone: response.UserAttributes?.find(
              (item) => item.Name === "phone_number"
            ).Value,
            UserRole: response.UserAttributes?.find(
              (item) => item.Name === "custom:role"
            ).Value,
            UserTenants: response.UserAttributes?.find(
              (item) => item.Name === "custom:tenants"
            ).Value,
          };

          dispatch(fetchUserDetailSuccess(obj));
          return obj;
        },
        (error) => {
          dispatch(fetchUserDetailFailure(error));
          return error;
        }
      )
      .catch((error) => {
        dispatch(fetchUserDetailFailure(error));
        return error;
      });
  };
};
export const updateSupportUser = (id, data) => {
  return (dispatch) => {
    return userService.updateUsers(id, data).then(
      (response) => {
        dispatch(getSupportUserByID(id));
        return response;
      },
      (error) => {
        error.error = true;
        return error;
      }
    );
  };
};
export const createSupportUser = (data) => {
  return (dispatch) => {
    return userService.createUsers(data).then(
      (response) => {
        dispatch(getSupportUsers());
        return response;
      },
      (error) => {
        error.error = true;
        return error;
      }
    );
  };
};

export const deleteSupportUser = (id) => {
  return (dispatch) => {
    return userService.deleteUser(id).then(
      (response) => {
        dispatch(getSupportUsers());
        return response;
      },
      (error) => {
        error.error = true;
        return error;
      }
    );
  };
};
export const filterSupportUser = (filter) => {
  return (dispatch, state) => {
    const data = state().supportUser.allCopyUser;
    const allCopyTenant = JSON.parse(JSON.stringify(data));
    let filtered = allCopyTenant || [];
    if (filter.search) {
      filtered = _.filter(filtered, (res) => {
        return (
          res.UserDisplayName.toLowerCase().indexOf(
            filter.search.toLowerCase()
          ) !== -1 ||
          res.EmployeeID.toLowerCase().indexOf(filter.search.toLowerCase()) !==
            -1 ||
          res.UserEmail.toLowerCase().indexOf(filter.search.toLowerCase()) !==
            -1
        );
      });
    }
    dispatch(fetchUserSuccess(filtered));
  };
};
