import React, { Component, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import CreateSupportUser from "./createSupportUser";
import "./supportTopBar.scss";

import appActions from "../../../layout/MainLayout/action/user.actions";
import { getAllTenant } from "../../tenant/action/tenant.actions";
const { toggleCollapsed } = appActions;

/* eslint eqeqeq: 0 */
const SupportTopBar = (props) => {
  const { toggleCollapsed, customizedTheme, collapsed, openDrawer } =
    props || {};
  const dispatch = useDispatch();
  const { allTenant } = useSelector((state) => state.tenant);

  const [createModal, setCreateModal] = useState(false);

  const openCreateModal = () => {
    setCreateModal(true);
  };

  const toggleCreateModal = () => {
    setCreateModal(false);
  };

  useEffect(() => {
    dispatch(getAllTenant());
  }, []);

  const Collapsed = collapsed && !openDrawer;

  return (
    <div className="tenant-top-bar">
      <div
        className={
          Collapsed ? "menu-bar-icon menuCollapsed" : "menu-bar-icon menuOpen"
        }
        style={{ color: customizedTheme.textColor }}
        onClick={toggleCollapsed}
      />
      <div className="txt-tenant-header">Support Team</div>

      <div
        className="btn-add-tenant"
        onClick={() => {
          openCreateModal();
        }}
      >
        CREATE SUPPORT USER
      </div>

      <CreateSupportUser
        modal={createModal}
        toggle={toggleCreateModal}
        allTenant={allTenant}
      />
    </div>
  );
};

export default connect(
  (state) => ({
    ...state.APP.App.toJS(),
    customizedTheme: "themedefault",
    ...state.authentication,
  }),
  { toggleCollapsed }
)(SupportTopBar);
