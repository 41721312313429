import {
  requestGet,
  requestPost,
  requestPut,
  requestDelete,
} from "../../../services/requests";
/**
 * Supports all operations exposed by the user controller.
 */

/* eslint eqeqeq: 0 */
export class UsersService {
  constructor(authEndpoint) {
    this.authEndpoint = authEndpoint;
  }

  getAllUsers() {
    const url = this.authUrl("/tenants/oloid-support/users");
    return requestGet(url, {}, true).then((res) => {
      return res?.data;
    });
  }

  getUsersById(id) {
    const url = this.authUrl(`/tenants/oloid-support/user/${id}`);
    return requestGet(url).then((res) => {
      return res?.data;
    });
  }

  createUsers(tenant) {
    const url = this.authUrl("/tenants/oloid-support/user");
    return requestPost(url, tenant).then((res) => {
      return res;
    });
  }

  updateUsers(emailID, tenant) {
    const url = this.authUrl(`/tenants/oloid-support/user/${emailID}`);
    return requestPut(url, tenant).then((res) => {
      return res?.data;
    });
  }

  deleteUser(emailID) {
    const url = this.authUrl(`/tenants/oloid-support/user/${emailID}`);
    return requestDelete(url).then((res) => {
      return res?.data;
    });
  }

  authUrl(append) {
    return this.authEndpoint + append;
  }
}
