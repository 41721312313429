import React, { Component } from 'react';
import { googleTenantLogin } from '../action/user.actions';
import { history } from '../../../helpers';
import LogoImageSupport from '../../../image/website-logo.svg';
import GoogleSSOLogin from '../../ssoLogin/googleSSOLogin';
import { connect } from 'react-redux';
import { message } from 'antd';

class Login extends Component {

	handleLogin = async (googleData) => {
    const { dispatch } = this.props;    
    try {
        const response = await dispatch(googleTenantLogin(googleData));
        if (response && response?.data?.AccessToken) {
            history.push('/tenants');
        } else {
            message.error(response?.message);
        }
    } catch (error) {
        console.error("An error occurred during login: ", error);
    }
  };

	handleGoogleAuthFailure = (error) => {
		console.log(error);
	};

	render() {
		return (
			<div className='login-container-support'>
				<div className='login-box'>
					<img className='support-logo' src={LogoImageSupport} alt="" />
					<div className='txt-login'>Log in to your account</div>
					<GoogleSSOLogin onGoogleSignIn={(credential) => this.handleLogin(credential)} />
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	const { user } = state.authentication;
	return {
		user,
	};
}
export default connect(mapStateToProps)(Login);
