

import React, { Fragment } from 'react';
import emailIcon from '../../image/emailIcon.svg';
import ssoIcon from '../../image/SSO.svg';

const EmailLogin = ({
    onEmailSignIn = () => { },
    onError = () => { },
    platform
}) => {

    const triggerLogin = async () => {
            onEmailSignIn();
            window.parent.postMessage(
                {
                    sender: "emailLogin"
                },
                "*"
            );
    };

    return (
        <Fragment>
            <div className={platform === "TenantUser" ? "sso-login-user-box" : "sso-login-box"} onClick={() => triggerLogin()}>
                <div className='image-logo-container'>
                    {platform === "TenantUser" ? 
                        <img className='other-login-icons' src={ssoIcon} alt="ssoIcon"></img> : 
                        <img className='login-icons' src={emailIcon} alt="emailIcon"></img> }
                    
                </div>
                <div className='sso-name'>{platform === "TenantUser" ? "Other Login Options" : "Continue with Email"}</div>
            </div>
        </Fragment>
    );
};

export default EmailLogin;






