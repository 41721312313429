import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import appActions from '../action/user.actions';
import TopbarWrapper from './topbar.style';
import _ from 'lodash'
import TenantTopBar from '../../../modules/tenant/component/tenantTopBar';
import ReportTopBar from '../../../modules/report/component/reportTopBar';
import SupportTopBar from '../../../modules/supportUser/component/supportTopBar';

const { Header } = Layout;
const { toggleCollapsed } = appActions;

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.getTopBar = this.getTopBar.bind(this);
  }
  getTopBar(page) {
    const { toggleCollapsed, customizedTheme } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    let pageName = page.pathname.split('/');

    if (page.pathname.indexOf('detail') > -1) {
      pageName = '';
    }
    else if (page.pathname === "/supportTenant") {
      pageName = ['support Tenant'];
    }
    else if (page.pathname === "/apiStatus") {
      pageName = ['Api List'];
    }

    if (page.pathname === "/tenants") {
      return <TenantTopBar />;
    }
    else if (page.pathname === "/report") {
      return <ReportTopBar />;
    }
    else if (page.pathname === "/supportUser") {
      return <SupportTopBar />;
    }
    else {
      return (
        <div style={{ width: '100%' }}>
          <div
            className={
              collapsed ? 'leftBtn menuCollapsed' : 'leftBtn menuOpen'
            }
            style={{ color: customizedTheme.textColor }}
            onClick={toggleCollapsed}
          />
          <span className="page_name">{pageName ? _.last(pageName) : ''}</span>
        </div>
      )
    }
  }
  render() {
    const { customizedTheme, location } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: 'fixed',
      width: '100%',
      height: 70
    };
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? 'platformTopbar collapsed' : 'platformTopbar'
          }
        >
          {this.getTopBar(location)}
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  state => ({
    ...state.APP.App.toJS(),
    customizedTheme: 'themedefault'
  }),
  { toggleCollapsed }
)(Topbar);
