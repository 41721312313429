import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Popover } from 'antd';
import userpic from '../../../image/logo-profile.png';
import TopbarDropdownWrapper from './topbarDropdown.style';
import { history } from '../../../helpers'
import {
  logout
} from "../../../modules/user/action/user.actions";


class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  logout(e) {
    const { dispatch } = this.props;
    history.push('/login');
    dispatch(logout());
  }
  handleHelp = () => {
    window.open('http://localhost:3000');
  };

  render() {
    const { user } = this.props;
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        <a className="isoDropdownLink" onClick={this.handleHelp}>
          Help
        </a>
        <a className="isoDropdownLink" onClick={this.logout.bind(this)}>
          Logout
        </a>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div className="isoImgWrapper">
          {/* <img alt="user" src={userpic} /> */}
          <div className="login-user-bg">{user.username && user.username.charAt(0).toUpperCase()}</div>
          <span className="userActivity online" />
        </div>
      </Popover>
    );
  }
}
function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  }
}
export default connect(mapStateToProps)(TopbarUser);
