import React, { useEffect, useState } from 'react';
import GoogleSSOLogin from './googleSSOLogin';

import AzureAuthenticationButton from "./azure/azure-authentication-component";

import OktaSSOLogin from "./oktaSSOLogin";

import EmailLogin from "./EmailLogin";

import { userService } from "../user/service";

import './sso.scss';
import PingSSOLogin from './pingSSOLogin';
import CustomLogin from './customLogin';

const SSOLogin = () => {

    const [supportedLogin, setSupportedLogin] = useState([]);
    const [disableUsernameLogin, setDisableUsernameLogin] = useState(false);
    const [platform, setPlatform] = useState("");
    const [ssoConfig, setSsoConfig] = useState("");

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        let tenant = params.get('tenant');
        setPlatform(params.get('platfrom'));
    
        if(params.get('sso')) {
            setSsoConfig(JSON.parse(params.get('sso')));
        }

        if (tenant && platform) {
            getTenantInformation(tenant)
        }
    }, [platform])
    
    const getTenantInformation = (subDomain) => {

        userService.getTenantInfo(subDomain).then((res) => {
            if (res.data && res.data.Tenants && res.data.Tenants.length > 0 && platform) {
                if(platform === 'TenantUser') {
                    const supportedLogin = ssoConfig?.SSOOptions;
                    if (supportedLogin && supportedLogin.length > 0) {
                        setSupportedLogin(supportedLogin)
                    } else {
                        window.parent.postMessage(
                            {
                                sender: "customLogin"
                            },
                            "*"
                        );
                    }
                } else if (platform === 'TenantAdmin') {
                    const supportedLogin = res.data.Tenants[0].SupportedLogin;
                    const disableUsernamePasswordLogin =
                      res.data.Tenants[0]?.FeatureFlag
                        ?.DisableUsernamePasswordLogin;
                    if(disableUsernamePasswordLogin) {
                        setDisableUsernameLogin(disableUsernamePasswordLogin);
                    }
                    if (supportedLogin && supportedLogin.length > 0) {
                        setSupportedLogin(supportedLogin)
                    } else {
                        window.parent.postMessage(
                            {
                                sender: "emailLogin"
                            },
                            "*"
                        );
                    }
                }
            }
            else {
                window.parent.postMessage(
                    {
                        sender: "emailLogin"
                    },
                    "*"
                );
            }
        });
    };

    return (
        <div className='login-container'>
            <div className="login-card-wrap">
                <div className="login-card">
                    <div className='company-logo-wrap'>
                        <div className='company-logo'>
                            <img className='company-logo-image' src="/assets/images/new-oloid-logo.svg" alt="" />
                        </div>
                    </div>

                    <div className='sso-login-wrap'>
                        <div className='sso-logins'>
                            <div className='sso-logins-header'>Your admin has configured following login option(s)</div>
                            {
                                (supportedLogin.indexOf('GSuite') > -1 || supportedLogin.indexOf('Google') > -1) &&
                                <GoogleSSOLogin autoLogin={supportedLogin.length === 1} />
                            }
                            {
                                supportedLogin.indexOf('Azure') > -1 &&
                                <AzureAuthenticationButton autoLogin={supportedLogin.length === 1} />
                            }
                            {
                                supportedLogin.indexOf('Okta') > -1 &&
                                <OktaSSOLogin autoLogin={supportedLogin.length === 1} />
                            }
                            {
                                supportedLogin.indexOf('Ping') > -1 &&
                                <PingSSOLogin autoLogin={supportedLogin.length === 1} />
                            }

                            {supportedLogin.indexOf('Custom Button') > -1 && ssoConfig?.CustomButton?.Label && (
                                <>
                                    <CustomLogin 
                                        text={ssoConfig?.CustomButton?.Label}
                                        platform={platform}
                                     />
                                </>
                            )}
                        
                            {!disableUsernameLogin && (
                                <>
                                    <div className='a-divider a-divider-break'>
                                        <span className="or-txt">or</span>
                                    </div>
                                    <EmailLogin platform={platform} />
                                </>
                            )}
                          

                        </div>
                    </div>

                    <div className='help-link-box'>
                        <a className='help-link' href="https://oloid.help/" target="_blank" rel="noreferrer">
                        Help
                        </a>
                    </div>
                </div>
            </div>
            <div className="login-footer-container">
                    <p>Powered by Oloid</p>
                    <a href="https://www.oloid.ai/privacy-statement" target="_blank" rel="noreferrer">
                        Privacy Policy
                    </a>
            </div>

        </div>
    )
}
export default SSOLogin;