import { tenantConstants } from '../constants';
import { combineReducers } from 'redux'
import createReducer from '../../../helpers/createReducer'

const initialState = [];

const tenantReducer = createReducer(initialState)({
  [tenantConstants.GET_ALL_TENANT_SUCCESS]: (state, action) => action.payload,
  [tenantConstants.GET_ALL_TENANT_FAILURE]: (state, action) => action.payload,
})

const copyTenantReducer = createReducer(initialState)({
  [tenantConstants.GET_ALL_TENANT_COPY_SUCCESS]: (state, action) => action.payload,
})

const tenantDetailReducer = createReducer({})({
  [tenantConstants.GET_TENANT_DETAIL_SUCCESS]: (state, action) => action.payload,
  [tenantConstants.GET_TENANT_DETAIL_FAILURE]: (state, action) => action.payload,
})

const tenantSettingsReducer = createReducer({})({
  [tenantConstants.GET_TENANT_SETTINGS_SUCCESS]: (state, action) => action.payload,
  [tenantConstants.GET_TENANT_SETTINGS_FAILURE]: (state, action) => ({}),
});


export default combineReducers({
  allTenant: tenantReducer,
  allCopyTenant: copyTenantReducer,
  tenantDetail: tenantDetailReducer,
  tenantSettings: tenantSettingsReducer,
})