import { Remove, TickBox } from "./tick.style";
import React from "react";

const Tick = () => {
  return <TickBox className="tick" />;
};
export const RemoveIcon = () => {
  return <Remove icon="icon-x-close" />;
};
export default Tick;
