import styled from "styled-components";
import { FlexDiv } from "../Box/Box";
import { StyledSvgSprite } from "../SvgSprite";

export const TickBox = styled(FlexDiv)`
  height: 19px;
  width: 19px;
  border-radius: 0.3125rem;
  border: 1px solid ${({ theme }) => theme.gray.shade_300};
  position: relative;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  &:after {
    position: absolute;
    display: table;
    border: 2px solid transparent;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 0;
    top: 45%;
    left: 28.5%;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: " ";
    width: 0;
    height: 0;
    transform-origin: center;
  }
`;
export const Remove = styled(StyledSvgSprite)`
  height: 12px;
  width: 12px;

  fill: ${({ theme }) => theme.primary.mediumBlue.shade_500};
`;
