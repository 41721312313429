import React, { useEffect, Fragment } from 'react';
import oktaIcon from '../../image/okta.png';

const OktaSSOLogin = ({
    onOktaSignIn = () => { },
    onError = () => { },
    text = "Continue with Google",
    autoLogin
}) => {

    const triggerLogin = async () => {
        onOktaSignIn();
        window.parent.postMessage(
            {
                sender: "oktaLogin"
            },
            "*"
        );
    };

    useEffect(() => {
        if (autoLogin) {
            triggerLogin();
        }
    }, [autoLogin]);

    return (
        <Fragment>
            <div className='sso-login-box' onClick={triggerLogin}>
                <div className='image-logo-container'>
                    <img className='login-icons' src={oktaIcon} alt="GoogleIcon"></img>
                </div>
                <div className='sso-name'>Continue with Okta</div>
            </div>
        </Fragment>
    );
};

export default OktaSSOLogin;
