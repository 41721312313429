import React, { Component } from 'react';
import { connect } from 'react-redux';
import GoogleLogin from "react-google-login";
import { googleLogin } from '../action/user.actions'
import { history } from '../../../helpers';
import LogoImageSupport from '../../../image/website-logo.svg';
import GoogleIcon from "../../../image/google-icon.png";
import './loginSupport.scss';
import GoogleSSOLogin from '../../ssoLogin/googleSSOLogin';

class LoginSupport extends Component {
	constructor(props) {
		super(props);
		this.state = {

		}
	}

	handleLogin = (googleData) => {
		const { dispatch } = this.props;
		console.log(googleData);

		// store returned user somehow
		dispatch(googleLogin(googleData));
		history.push('/supportTenant');
	};
	handleGoogleAuthFailure = (error) => {
		console.log(error);
	};

	render() {
		return (
			<div className='login-container-support'>

				{/* <div className='login-box'>
					<img className='support-logo' src={LogoImageSupport} alt=""/>
					<div className='txt-login'>Log in to your account</div>

					<GoogleLogin
						clientId={process.env.REACT_APP_Google_APP_CLIENT_ID}
						buttonText="Log in with Google"
						render={(renderProps) => (
							<div className="sso-login-box" onClick={renderProps.onClick}>
								<div className="image-logo-container">
									<img
										className="login-icons"
										src={GoogleIcon}
										alt="GoogleIcon"
									/>
								</div>
								<div className="sso-name">Log in with Google</div>
							</div>
						)}
						onSuccess={this.handleLogin}
						onFailure={this.handleGoogleAuthFailure}
						cookiePolicy={"single_host_origin"}
					/>
				</div> */}
				<div className='login-box'>
					<img className='support-logo' src={LogoImageSupport} alt="" />
					<div className='txt-login'>Log in to your account</div>
					<GoogleSSOLogin onGoogleSignIn={(credential) => this.handleLogin(credential)} />
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	const { user } = state.authentication;
	return {
		user,
	}
}
export default connect(mapStateToProps)(LoginSupport);