import React, { Component } from "react";
import "./createTenant.scss";
import { Modal, Button, message, Col } from "antd";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { createTenant } from "../action/tenant.actions";
import { connect } from "react-redux";
import AntSelect, { AntSelectOption } from "../../../components/atoms/Select";

class CreateTenant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant: {
        tenantName: "",
        region:
          window.location.host === "oloiddev.com" ||
          window.location.host === "oloid.info"
            ? "us-east-2"
            : "us-east-1",
        customerID: "",
        customerName: "",
        tenantOwnerName: "",
        tenantOwnerEmail: "",
        tenantOwnerPassword: "",
        SupportedLogin: [],
        AllowedDomains: [],
      },
      error: {},
      isSubmit: false,
    };
  }

  handleOk = () => {
    this.setState({ isSubmit: true });
    if (this.validateForm()) {
      const { dispatch } = this.props;
      this.setState({ loading: true });
      const tenant = this.state.tenant;
      const reqObj = {
        tenantName: tenant.tenantName.trim(),
        region: tenant.region,
        customerID: tenant.customerID,
        customerName: tenant.customerName,
        tenantOwnerName: tenant.tenantOwnerName,
        tenantOwnerEmail: tenant.tenantOwnerEmail,
        Password: tenant.tenantOwnerPassword,
        SupportedLogin: tenant.SupportedLogin,
        AllowedDomains: tenant.AllowedDomains,
      };
      dispatch(createTenant(reqObj)).then((res) => {
        if (res.error) {
          message.error(
            res.message || "Something went wrong please try agian!"
          );
          return;
        }
        message.success("Tenant Created Successfully");
        this.resetFrom();
        this.setState({ loading: false });
        this.props.toggle();
      });
    }
  };

  resetFrom = () => {
    this.setState({
      tenant: {
        tenantName: "",
        region:
          window.location.host === "oloiddev.com" ||
          window.location.host === "oloid.info"
            ? "us-east-2"
            : "us-east-1",
        customerID: "",
        customerName: "",
        tenantOwnerName: "",
        tenantOwnerEmail: "",
        tenantOwnerPassword: "",
        SupportedLogin: [],
        AllowedDomains: [],
      },
      error: {},
      isSubmit: false,
    });
  };
  handleCancel = () => {
    this.resetFrom();
    this.props.toggle();
  };

  passwordValidation = (email, password) => {
    var minMaxLength = /^[\s\S]{8,32}$/,
      upper = /[A-Z]/,
      lower = /[a-z]/,
      number = /[0-9]/,
      dictionaryWords = ["password", "helpme", "pa$$word"];

    if (!minMaxLength.test(password)) {
      return false;
    }
    if (!upper.test(password)) {
      return false;
    }
    if (!lower.test(password)) {
      return false;
    }
    if (!number.test(password)) {
      return false;
    }

    if (/(.)\1\1/.test(password)) {
      return false;
    }
    if (dictionaryWords.indexOf(password.toLowerCase()) > -1) {
      return false;
    }

    var partsOfThreeLetters = email
      .match(/.{4}/g)
      .concat(email.substr(1).match(/.{4}/g), email.substr(2).match(/.{4}/g));
    if (new RegExp(partsOfThreeLetters.join("|"), "i").test(password)) {
      return false;
    }

    // Check for sequential numerical characters
    for (var i in password) {
      if (
        +password[+i + 1] === +password[i] + 1 &&
        +password[+i + 2] === +password[i] + 2
      ) {
        return false;
      }
    }
    // Check for sequential alphabetical characters
    for (var i in password) {
      if (
        String.fromCharCode(password.charCodeAt(i) + 1) == password[+i + 1] &&
        String.fromCharCode(password.charCodeAt(i) + 2) == password[+i + 2]
      ) {
        return false;
      }
    }
    return true;
  };

  validateForm = () => {
    const { tenant } = this.state;
    let error = {};
    if (!tenant.tenantName) {
      error.tenantName = true;
    }
    if (!tenant.customerName) {
      error.customerName = true;
    }
    if (tenant.customerName && tenant.customerName.length < 4) {
      error.customerNamelength = true;
    }
    if (!tenant.tenantOwnerName) {
      error.tenantOwnerName = true;
    }
    if (!tenant.region) {
      error.region = true;
    }
    if (!tenant.tenantOwnerEmail) {
      error.tenantOwnerEmail = true;
    }
    if (!tenant.tenantOwnerPassword) {
      error.tenantOwnerPassword = true;
    }

    if (
      tenant.tenantOwnerEmail &&
      tenant.tenantOwnerPassword &&
      !this.passwordValidation(
        tenant.tenantOwnerEmail,
        tenant.tenantOwnerPassword
      )
    ) {
      error.tenantOwnerPassword = true;
    }

    this.setState({ error });
    return Object.keys(error).length == 0;
  };

  handleInput = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    const { tenant, error } = Object.assign({}, this.state);
    error[name] = false;
    var letters = /^[A-Za-z0-9-]+$/;
    if (name === "tenantName") {
      if (value.match(letters) || e.target.value === "") {
        tenant[name] = value;
      }
    } else if (name === "tenantOwnerEmail") {
      tenant[name] = value?.toLowerCase()?.trim();
    } else {
      tenant[name] = value;
    }
    this.setState({ tenant, error });
  };

  handleSelect = (e) => {
    const value = e.target.value;
    const { tenant, error } = Object.assign({}, this.state);
    tenant["region"] = value;
    error["region"] = false;
    this.setState({ tenant, error });
  };

  handleMultiSelectChange = (value, name) => {
    const { tenant } = Object.assign({}, this.state);
    tenant[name] = value;
    this.setState({ tenant });
  };

  render() {
    const { error, isSubmit, tenant } = this.state;
    let host = window.location.host;
    let hostDomin = [
      "oloid-demo.com",
      "onoloid.com",
      "localhost:3000",
      "oloiddev.com",
    ];
    let isRegionDisable = hostDomin.indexOf(host) > -1;
    return (
      <div>
        <Modal
          className="create-tenant"
          visible={this.props.modal}
          onCancel={this.handleCancel}
          centered
          maskClosable={false}
          width={600}
          footer={[
            <div className="footer-box">
              <Button
                className="create-btn"
                key="back"
                type="primary"
                onClick={this.handleOk}
              >
                CREATE
              </Button>
              <Button
                className="cancle-btn"
                key="submit"
                onClick={this.handleCancel}
              >
                CANCEL
              </Button>
            </div>,
          ]}
        >
          <div className="txt-header">Create a new tenant</div>
          <div className="grid-two-col-layout">
            <div className="position-rel">
              <TextField
                error={error.tenantName}
                name="tenantName"
                value={tenant.tenantName}
                label="*Tenant Name"
                type="text"
                className="tenant-form-textfield"
                margin="normal"
                variant="outlined"
                helperText={error.tenantName ? "Required" : ""}
                onChange={(e) => this.handleInput(e)}
              />
              {isSubmit && !error.tenantName && (
                <div className="success-icon position"></div>
              )}
              {error.tenantName && (
                <div className="success-icon position error"></div>
              )}
            </div>
            <div className="position-rel">
              <TextField
                error={error.customerName || error.customerNamelength}
                name="customerName"
                value={tenant.customerName}
                label="*Customer Name"
                rows="4"
                className="tenant-form-textfield"
                margin="normal"
                variant="outlined"
                helperText={
                  error.customerName
                    ? "Required"
                    : error.customerNamelength
                    ? "Error : atleast 4 character"
                    : ""
                }
                onChange={(e) => this.handleInput(e)}
              />
              {isSubmit &&
                !(error.customerName || error.customerNamelength) && (
                  <div className="success-icon position"></div>
                )}
              {(error.customerName || error.customerNamelength) && (
                <div className="success-icon position error"></div>
              )}
            </div>

            <div className="position-rel">
              <TextField
                name="customerID"
                value={tenant.customerID}
                label="Customer ID"
                rows="4"
                className="tenant-form-textfield"
                margin="normal"
                variant="outlined"
                helperText={error.customerID ? "Required" : ""}
                onChange={(e) => this.handleInput(e)}
              />
              {isSubmit && !error.customerID && (
                <div className="success-icon position"></div>
              )}
              {error.customerID && (
                <div className="success-icon position error"></div>
              )}
            </div>

            <div className="position-rel">
              <FormControl
                variant="outlined"
                className="region-select"
                error={error.region}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  *Region
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  disabled={isRegionDisable}
                  value={tenant.region}
                  onChange={(e) => this.handleSelect(e)}
                  inputProps={{
                    name: "age",
                    id: "outlined-age-native-simple",
                  }}
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="us-east-1">us-east-1</MenuItem>
                  <MenuItem value="us-east-2">us-east-2</MenuItem>
                  <MenuItem value="us-west-1">us-west-1</MenuItem>
                  <MenuItem value="us-west-2">us-west-2</MenuItem>
                </Select>
                {error.region && <FormHelperText>Required</FormHelperText>}
              </FormControl>
            </div>
            <div className="position-rel">
              <TextField
                error={error.tenantOwnerName}
                name="tenantOwnerName"
                value={tenant.tenantOwnerName}
                label="*Owner Name"
                rows="4"
                className="tenant-form-textfield"
                margin="normal"
                variant="outlined"
                helperText={error.tenantOwnerName ? "Required" : ""}
                onChange={(e) => this.handleInput(e)}
              />
              {isSubmit && !error.tenantOwnerName && (
                <div className="success-icon position"></div>
              )}
              {error.tenantOwnerName && (
                <div className="success-icon position error"></div>
              )}
            </div>
            <div className="position-rel">
              <TextField
                error={error.tenantOwnerEmail}
                name="tenantOwnerEmail"
                value={tenant.tenantOwnerEmail}
                label="*Tenant owner email"
                rows="4"
                className="tenant-form-textfield"
                margin="normal"
                variant="outlined"
                helperText={error.tenantOwnerEmail ? "Required" : ""}
                onChange={(e) => this.handleInput(e)}
              />
              {isSubmit && !error.tenantOwnerEmail && (
                <div className="success-icon position"></div>
              )}
              {error.tenantOwnerEmail && (
                <div className="success-icon position error"></div>
              )}
            </div>

            <div className="position-rel">
              <TextField
                type="password"
                error={error.tenantOwnerPassword}
                name="tenantOwnerPassword"
                value={tenant.tenantOwnerPassword}
                label="*Tenant owner password"
                rows="4"
                className="tenant-form-textfield"
                margin="normal"
                variant="outlined"
                helperText={
                  error.tenantOwnerPassword
                    ? "Minimum 8 characters with at least 1 Upper Case, 1 lower case, and 1 numeric character"
                    : ""
                }
                onChange={(e) => this.handleInput(e)}
              />
              {isSubmit && !error.tenantOwnerPassword && (
                <div className="success-icon position"></div>
              )}
              {error.tenantOwnerPassword && (
                <div className="success-icon position error"></div>
              )}
            </div>
            <div className="position-rel" id="supportMenuCreate">
              <Col span={24} className="from-left-col">
                <AntSelect
                  mode="multiple"
                  getPopupContainer={() =>
                    document.getElementById("supportMenuCreate")
                  }
                  allowClear
                  placeholder="Select Supported Login"
                  name="SupportedLogin"
                  value={tenant?.SupportedLogin}
                  size="large"
                  onChange={(e) => {
                    this.handleMultiSelectChange(e, "SupportedLogin");
                  }}
                >
                  <AntSelectOption key="GSuite" value={"GSuite"}>
                    GSuite
                  </AntSelectOption>
                  <AntSelectOption key="Azure" value={"Azure"}>
                    Azure
                  </AntSelectOption>
                  <AntSelectOption key="Okta" value={"Okta"}>
                    Okta
                  </AntSelectOption>
                  <AntSelectOption key="OneLogin" value={"OneLogin"}>
                    OneLogin
                  </AntSelectOption>
                </AntSelect>
              </Col>
            </div>
            <div className="position-rel" id="allowedDomains">
              <Col span={24} className="from-left-col">
                <AntSelect
                  mode="tags"
                  allowClear
                  getPopupContainer={() =>
                    document.getElementById("allowedDomains")
                  }
                  placeholder="Select Allowed Domains"
                  name="AllowedDomains"
                  value={tenant?.AllowedDomains}
                  size="large"
                  onChange={(e) => {
                    this.handleMultiSelectChange(e, "AllowedDomains");
                  }}
                  notFoundContent={"Enter DomainName and hit enter"}
                />
              </Col>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}
export default connect(mapStateToProps)(CreateTenant);
