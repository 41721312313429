import { userConstants } from "../constants";
import { combineReducers } from "redux";
import createReducer from "../../../helpers/createReducer";

const initialState = [];
const initialDetailState = {};

const supportUserReducer = createReducer(initialState)({
  [userConstants.GET_ALL_USER_SUCCESS]: (state, action) => action.payload,
  [userConstants.GET_ALL_USER_FAILURE]: (state, action) => [],
});

const copyUserReducer = createReducer(initialState)({
  [userConstants.GET_ALL_USER_COPY_SUCCESS]: (state, action) => action.payload,
});

const supportUserDetailReducer = createReducer(initialDetailState)({
  [userConstants.GET_USER_DETAIL_SUCCESS]: (state, action) => action.payload,
  [userConstants.GET_USER_DETAIL_FAILURE]: (state, action) => {},
});

export default combineReducers({
  supportAllUser: supportUserReducer,
  allCopyUser: copyUserReducer,
  supportUserDetail: supportUserDetailReducer,
});
