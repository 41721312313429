import React, { Fragment, useEffect } from 'react';
import pingIcon from '../../image/ping.png';

const PingSSOLogin = ({
    text = "Continue with Ping", autoLogin
}) => {

    const triggerLogin = async () => {
        window.parent.postMessage(
            {
                sender: "pingLogin"
            },
            "*"
        );
    };

    useEffect(() => {
        if (autoLogin) {
            triggerLogin();
        }
    }, [autoLogin]);

    return (
        <Fragment>
            <div className='sso-login-box' onClick={triggerLogin}>
                <div className='image-logo-container'>
                    <img className='login-icons' src={pingIcon} alt="GoogleIcon"></img>
                </div>
                <div className='sso-name'>{text}</div>
            </div>
        </Fragment>
    );
};

export default PingSSOLogin;
