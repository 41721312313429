import React, { useState } from "react";
import "./createSupportUser.scss";
import { Modal, Button, message, Row, Col, Select } from "antd";
import { TextField } from "@material-ui/core";
import AntSelect from "../../../components/atoms/Select";
import { useDispatch, useSelector } from "react-redux";
import { createSupportUser } from "../action/supportUser.actions";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

const { Option } = Select;

const CreateSupportUser = (props) => {
  const { toggle, allTenant, modal } = props || {};
  const { supportAllUser } = useSelector((state) => state.supportUser);

  const dispatch = useDispatch();
  const initialUserDetails = {
    UserTenants: [],
    UserRole: undefined,
    UserEmail: "",
    EmployeeID: "",
    UserDisplayName: "",
    UserPhone: "",
    //UserAccessLevel: [],
  };
  const initialErrors = {
    UserTenants: false,
    UserRole: false,
    UserEmail: false,
    EmployeeID: false,
    ExistingEmployeeID: false,
    ExistingPhone: false,
    ExistingEmail: false,
    IsValidEmail: false,
    isInValidDomain: false,
    UserDisplayName: false,
    UserPhone: false,
    InvalidUserPhone: false,
    //UserAccessLevel: false,
  };
  const [userDetails, setUserDetail] = useState(initialUserDetails);
  const [error, setError] = useState(initialErrors);
  const validateForm = () => {
    let errorObj = {};
    if (!userDetails?.UserPhone) {
      errorObj.UserPhone = true;
    }
    if (userDetails?.UserPhone && !isValidPhoneNumber(userDetails.UserPhone)) {
      errorObj.InvalidUserPhone = true;
    }

    if (!userDetails?.EmployeeID) {
      errorObj.EmployeeID = true;
    }
    if (userDetails?.EmployeeID) {
      let existingID = supportAllUser?.find(
        (el) => el.EmployeeID === userDetails?.EmployeeID
      );
      if (existingID) {
        errorObj.ExistingEmployeeID = true;
      }
    }
    if (userDetails?.UserPhone) {
      let existingPhone = supportAllUser?.find(
        (el) => el.UserPhone === userDetails?.UserPhone
      );
      if (existingPhone) {
        errorObj.ExistingPhone = true;
      }
    }
    if (userDetails?.UserEmail) {
      let existingEmail = supportAllUser?.find(
        (el) => el.UserEmail === userDetails?.UserEmail
      );
      const validateEmail = (email) => {
        const emailRegex = /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return emailRegex.test(email);
      };
      if (existingEmail) {
        errorObj.ExistingEmail = true;
      }
      if (!validateEmail(userDetails?.UserEmail) && !existingEmail) {
        errorObj.IsValidEmail = true;
      }
      if (validateEmail(userDetails?.UserEmail) && !existingEmail) {
        if (
          !(
            userDetails?.UserEmail?.endsWith("@oloid.ai") ||
            userDetails?.UserEmail?.endsWith("@xcdify.com")
          )
        ) {
          errorObj.isInValidDomain = true;
        }
      }
    }
    if (!userDetails?.UserEmail) {
      errorObj.UserEmail = true;
    }
    if (!userDetails?.UserRole) {
      errorObj.UserRole = true;
    }
    if (!userDetails?.UserDisplayName) {
      errorObj.UserDisplayName = true;
    }
    if (userDetails?.UserTenants?.length === 0) {
      errorObj.UserTenants = true;
    }
    // if (userDetails?.UserAccessLevel?.length === 0) {
    //   errorObj.UserAccessLevel = true;
    // }

    setError(errorObj);

    return Object.keys(errorObj)?.length === 0;
  };
  const handleCancel = () => {
    setUserDetail(initialUserDetails);
    setError(initialErrors);
    toggle();
  };
  const handleOk = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let apiData = {
        Role: userDetails?.UserRole,
        Phone: userDetails?.UserPhone,
        Name: userDetails?.UserDisplayName,
        EmployeeId: userDetails?.EmployeeID,
        AccessPolicy:
          userDetails?.UserRole === "SupportUser" ? "read" : "read,write",
        Tenants: userDetails?.UserTenants?.includes("*")
          ? "*"
          : userDetails?.UserTenants.join(","),
        Email: userDetails?.UserEmail,
      };
      dispatch(createSupportUser(apiData)).then((res) => {
        if (res?.error) {
          message.error(res?.message);
        } else {
          message.success("User created successfully");
          setUserDetail(initialUserDetails);
          toggle();
        }
      });
    }
  };

  const handleSelectChange = (value, field) => {
    if (field === "UserTenants") {
      if (value.includes("*")) {
        let allValues = allTenant?.map((item) => item.TenantName);
        setUserDetail({ ...userDetails, [field]: ["*", ...allValues] });
      } else if (!value.includes("*")) {
        if (value.length === allTenant?.length) {
          return setUserDetail({ ...userDetails, [field]: [] });
        }
        setUserDetail({ ...userDetails, [field]: value });
      }
      setError({ ...error, [field]: false });
    } else {
      setUserDetail({ ...userDetails, [field]: value });
      if (field !== "UserStatus") {
        setError({ ...error, [field]: false });
      }
    }
  };

  const handleInputChange = (value, field) => {
    if (field === "UserDisplayName") {
      let nameRule = /^[a-zA-Z\s]*$/;
      if (value.match(nameRule) || value === "") {
        setUserDetail({ ...userDetails, [field]: value });
        setError({ ...error, [field]: false });
      }
    } else if (field === "EmployeeID") {
      setUserDetail({ ...userDetails, [field]: value });
      setError({ ...error, [field]: false, ExistingEmployeeID: false });
    } else if (field === "UserPhone") {
      setUserDetail({ ...userDetails, [field]: value });
      setError({ ...error, [field]: false, InvalidUserPhone: false });
    } else {
      setUserDetail({ ...userDetails, [field]: value });
      setError({ ...error, [field]: false });
    }
  };

  return (
    <Modal
      className="create-tenant create-support-user"
      visible={modal}
      onCancel={handleCancel}
      maskClosable={false}
      centered
      width={600}
      footer={[
        <div className="footer-box">
          <Button
            className="create-btn"
            key="back"
            type="primary"
            onClick={handleOk}
          >
            CREATE
          </Button>
          <Button className="cancle-btn" key="submit" onClick={handleCancel}>
            CANCEL
          </Button>
        </div>,
      ]}
    >
      <div className="txt-header">Create a new support user</div>
      <Row className="mb-20 grid-2-columns">
        <Col span={24} className="from-left-col">
          <div className="position-relative">
            <label className="level-text">Name</label>
            <TextField
              id="displayName"
              type="text"
              className="form-textfield"
              variant="outlined"
              placeholder="Full Name"
              value={userDetails.UserDisplayName}
              onChange={(e) => {
                handleInputChange(e.target.value, "UserDisplayName");
              }}
            />
            {error.UserDisplayName && (
              <div className="error-msg">Please enter name</div>
            )}
          </div>
        </Col>
        <Col span={24} className="from-left-col">
          <div className="position-relative">
            <label className="level-text">Email</label>
            <TextField
              id="EmailID"
              type="text"
              className="form-textfield"
              variant="outlined"
              placeholder="Email"
              value={userDetails.UserEmail}
              onChange={(e) => {
                handleInputChange(e.target.value, "UserEmail");
              }}
            />
            {error.UserEmail && (
              <div className="error-msg">Please enter email</div>
            )}
            {error.ExistingEmail && (
              <div className="error-msg">Email already exists</div>
            )}
            {error.IsValidEmail && (
              <div className="error-msg">Email is not valid</div>
            )}
            {error.isInValidDomain && (
              <div className="error-msg">Email domain is not allowed</div>
            )}
          </div>
        </Col>
        <Col span={24} className="from-left-col">
          <div className="position-relative">
            <label className="level-text">Phone Number</label>

            <PhoneInput
              id="phone_number"
              className="form-textfield form-phonefield form-textfield-Error"
              country="US"
              defaultCountry="US"
              placeholder="Phone Number"
              name="UserPhone"
              value={userDetails.UserPhone}
              onChange={(value) => {
                handleInputChange(value, "UserPhone");
              }}
            />
            {error.UserPhone && (
              <div className="error-msg error-top-margin">Please enter phone number</div>
            )}
            {error.InvalidUserPhone && (
              <div className="error-msg error-top-margin">Please enter valid phone number</div>
            )}
            {error.ExistingPhone && (
              <div className="error-msg error-top-margin">Phone number already exists</div>
            )}
          </div>
        </Col>
        <Col span={24} className="from-left-col">
          <div className="position-relative">
            <label className="level-text">Employee ID</label>
            <TextField
              id="EmployeeID"
              type="text"
              className="form-textfield"
              variant="outlined"
              placeholder="Employee ID"
              name="EmployeeID"
              value={userDetails?.EmployeeID}
              onChange={(e) => {
                handleInputChange(e.target.value, "EmployeeID");
              }}
            />
            {error.EmployeeID && (
              <div className="error-msg">Please enter employee ID</div>
            )}
            {error.ExistingEmployeeID && (
              <div className="error-msg">Employee ID already exists</div>
            )}
          </div>
        </Col>
        <Col span={24} className="from-left-col" id="roleMenu">
          <div className="position-relative">
            <label className="level-text">Role</label>
            <AntSelect
              getPopupContainer={() => document.getElementById("roleMenu")}
              placeholder="Role"
              value={userDetails.UserRole}
              name="UserRole"
              onChange={(e) => handleSelectChange(e, "UserRole")}
              allowClear={false}
            >
              <Option value={"SupportUser"}>Support User</Option>
              <Option value={"SupportAdmin"}>Admin</Option>
              <Option value={"SupportEditor"}>Supervisor</Option>
            </AntSelect>
            {error.UserRole && (
              <div className="error-msg error-top-margin">Please select role</div>
            )}
          </div>
        </Col>
        {/* <Col span={24} className="from-left-col" id="accessMenu">
          <div className="position-relative">
            <label className="level-text">Access Level</label>
            <AntSelect
              getPopupContainer={() => document.getElementById("accessMenu")}
              mode="multiple"
              placeholder="Access Level"
              value={userDetails?.UserAccessLevel}
              name="UserAccessLevel"
              onChange={(e) => handleSelectChange(e, "UserAccessLevel")}
            >
              <Option value={"read"}>Read</Option>
              <Option value={"write"}>Write</Option>
            </AntSelect>
            {error.UserAccessLevel && (
              <div className="error-msg">Please select access level</div>
            )}
          </div>
        </Col> */}
        <Col span={24} className="from-left-col" id="createSupportUser">
          <div className="position-relative">
            <label className="level-text">Tenants</label>
            <AntSelect
              getPopupContainer={() =>
                document.getElementById("createSupportUser")
              }
              mode="multiple"
              placeholder="Tenant"
              value={userDetails?.UserTenants}
              name="UserTenants"
              onChange={(e) => handleSelectChange(e, "UserTenants")}
            >
              <Option key="all" value={"*"}>
                All
              </Option>
              {allTenant &&
                allTenant?.map((item, index) => (
                  <Option
                    disabled={userDetails?.UserTenants?.includes("*")}
                    key={index}
                    value={item.TenantName}
                  >
                    {item.TenantName}
                  </Option>
                ))}
            </AntSelect>
            {error.UserTenants && (
              <div className="error-msg error-top-margin">Please select tenant(s)</div>
            )}
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default CreateSupportUser;
