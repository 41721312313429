

import React, { Fragment } from 'react';
import emailIcon from '../../image/emailIcon.svg';
import badgeIcon from "../../image/badgeIcon.svg";
import faceIcon from "../../image/faceIcon.svg";

const CustomLogin = ({
    onEmailSignIn = () => { },
    onError = () => { },
    text = "Continue with Email",
}) => {

    const triggerLogin = async () => {
         window.parent.postMessage(
                {
                    sender: "customLogin"
                },
                "*"
            );
    };

    const buttonIcon = () => {
        const lowerText = text.toLowerCase();
        
        if (lowerText.includes('badge')) {
            return badgeIcon;
        } else if (lowerText.includes('face')) {
            return faceIcon;
        } else {
            return emailIcon;
        }
    }

    return (
        <Fragment>
            <div className='sso-login-box' onClick={() => triggerLogin()}>
                <div className='image-logo-container'>
                    <img className='login-icons' src={buttonIcon()} alt="buttonIcon"></img>
                </div>
                <div className='sso-name'>{text}</div>
            </div>
        </Fragment>
    );
};

export default CustomLogin;
