import React, { useEffect } from 'react';
import './App.scss';
import ErrorBoundary from './helpers/ErrorBoundary';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { history } from './helpers/history';
import MainLayout from './layout/MainLayout';
import Login from './modules/user/component/login';
import LoginSupport from './modules/user/component/loginSupport';

import ChangePassword from './modules/user/component/changePassword';
import Authentication from './modules/user/component/authentication';
import ResetPassword from './modules/user/component/resetPassword';
import ssoLogins from './modules/ssoLogin/ssoLogins';
import Amplify from 'aws-amplify';
import loader from './image/loader.gif';
import { syncLocalStorage } from './helpers/syncLocalStorage';
import AuthVerify from './AuthVerify';
import { logout } from './modules/user/action/user.actions';
import { useDispatch } from 'react-redux';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AMPLIFY_REGION,
    userPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AMPLIFY_WEB_CLIENT_ID,
  }
});

const App = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    syncLocalStorage();
  })

  const logOut = () => {
    history.push('/login');
    dispatch(logout());
  }

  return (
    <ErrorBoundary>
      <div className="App">
        <div className="ui_block ui-block-loader" id="ui_block">
          <div style={{
            margin: "auto",
            maxHeight: "100%"
          }}>
            <div>
              <img src={loader} alt="" />
            </div>
          </div>
        </div>
        <Router history={history}>
          <Switch>
            <Redirect exact={true} from='/' to='/login' />
            <Route exact path='/support' name='Login Page' component={LoginSupport} />
            <Route exact path='/login' name='Login Page' component={Login} />
            <Route exact path='/changePassword' name='ChangePassword' component={ChangePassword} />
            <Route exact path='/authentication' name='Authentication' component={Authentication} />
            <Route exact path='/resetPassword' name='ResetPassword' component={ResetPassword} />
            <Route exact path='/ssologin' name='ChangePassword' component={ssoLogins} />
            <Route exact path="/register" name="Register Page" component={(e) => { return <h3>Register Page</h3> }} />
            <Route exact path="/500" name="Page 500" component={(e) => { return <h3>not found</h3> }} />
            <Route exact path="/404" name="Page 404" component={(e) => { return <h3>not found</h3> }} />
            <Route path="/" name="Home" component={MainLayout} />

          </Switch>

          <AuthVerify logOut={logOut} />
        </Router>
      </div>
    </ErrorBoundary>
  );

}

export default App;