import React, { Component } from 'react';
import TopbarUser from '../Topbar/topbarUser';
import NotifictationUser from '../Topbar/notificationUser';
import { Link } from 'react-router-dom';
import { history } from '../../../helpers';
import options from '../Sidebar/options';
import LogoImage from '../../../image/logo.png';
import Icon1 from '../../../image/ic_dashboard.png';
import Icon1_Active from '../../../image/ic_dashboard_active.png';
import _ from 'lodash';
import { connect } from "react-redux";
import { setSelectedMenu } from "../action/sideFixedBarAction";

export class SideFixedBar extends Component {
  state = {
    selectedIndex: 0,
    Lists1: [{ id: 1, value: Icon1_Active }],
    Lists: [{ id: 1, value: Icon1_Active }]
  }
  componentWillMount() {
    const { location } = this.props;
    const currentItem = _.nth(_.split(location.pathname, '/'),1);
    const menus = options.find(function (res) { return res.key === currentItem });
    if (menus) {
      this.handleActive((menus.menuid - 1), true);
    }
  }

  handleActive = (index, isSelected) => {
    this.setState({ selectedIndex: index });
    if (index === 0) {
      this.setState({ Lists: this.state.Lists1 });
      if(!isSelected){
        history.push('/tenants')
      }
    }
    this.props.setSelectedMenu(this.state.Lists[index].id)
  }

  render() {
    const { locale } = this.props;
    return (
      <div className="leftBar">
        <ul>
          <li>
            <Link to="/dashboard">
              <div className="logo">
                <img alt="logo" src={LogoImage} />
              </div>
            </Link>
          </li>
          {this.state.Lists.map((value, index) => (
            <li onClick={(e) => { this.handleActive(index) }} key={`item-${index}`} className={(this.state.selectedIndex === index ? 'active' : '')}>
              <div className="icon">
                <img alt={value.value} src={value.value} />
              </div>
            </li>
          ))}
        </ul>
        <div className="profile-button isoUser">
          <div className="notification-box">
            <NotifictationUser />
          </div>
          <TopbarUser locale={locale} />
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state, props) => ({
  Selectedmenu: state.fixSideBar.fixSideBarReducer.menuId
});

const mapDispatchToProps = (dispatch, props) => ({
  setSelectedMenu: (id) => dispatch(setSelectedMenu(id))
});

const SideFixedBarContainer = connect(mapStateToProps, mapDispatchToProps)(
  SideFixedBar
);

export default SideFixedBarContainer;
