import { history } from "./history";

export const syncLocalStorage = () => {

    const onStorageUpdate = (e) => {
        const { key, newValue } = e;
        let user = {};
        if (key === "user") {
            if (newValue) {
                try {
                    user = JSON.parse(newValue);
                } catch (error) {

                }
            }

            if (user.role === 'Support') {
                history.push('/supportTenant')
            }
            else if (user.role === 'OloidSysAdmin') {
                history.push('/tenants')
            }
            window.location.reload();
        }
    };

    window.addEventListener("storage", onStorageUpdate);
}
