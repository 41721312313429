import { Select } from "antd";
import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import Tick, { RemoveIcon } from "../../../components/atoms/checkBoxTick";
import { filterOption } from "../../../helpers";
import { customScroll } from "../../shared/mixin";

function AntSelect(props) {
  const {
    mode = "default",
    children,
    allowClear = true,
    // maxTagCount = "responsive",
    showSearch = true,
    name = undefined,
    placeholder = "Select",
    ...rest
  } = props || {};
  const selectProps = {
    // maxTagCount: maxTagCount,
    allowClear: allowClear,
    mode: mode,
    showSearch: showSearch,
    menuItemSelectedIcon: () => <Tick />,
    removeIcon: <RemoveIcon />,
  };
  return (
    <>
      <Select
        {...selectProps}
        name={name}
        filterOption={filterOption}
        placeholder={placeholder}
        {...rest}
      >
        {children}
      </Select>
      <GlobalStyle />
    </>
  );
}

export default AntSelect;

const GlobalStyle = createGlobalStyle`
.ant-select-dropdown-menu{
  ${customScroll({})}
}
`;
export const AntSelectOption = styled(Select)``;
