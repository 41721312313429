import React from 'react';
import Loadable from 'react-loadable';
import MainLayout from './layout/MainLayout';

export const Routes = {
  UNKNOWN: '',
  LOGIN: '/login',
  ROOT: '/',
  DASHBOARD: '/dashboard',
  INVOICES: '/invoices',
  INVOICE_CREATE: '/create-upload',
  JOURNALS: '/journals',
  MANAGE_USERS: '/manage-users',
  SETTINGS: '/settings/node',
  SETTINGS_AZURE: '/settings/azure',
  PROFILE: '/profile',
  REPORT_DRILL_DOWN: '/report/drill-down/:accountId',
  REPORT_AGING: '/report/aging/:accountId'
}

function Loading() {
  return <div>Loading...</div>;
}
const Tenants = Loadable({
  loader: () => import('./modules/tenant/component/tenants'),
  loading: Loading,
});

const TenantDetail = Loadable({
  loader: () => import('./modules/tenant/component/tenantdetail'),
  loading: Loading,
});

const Report = Loadable({
  loader: () => import('./modules/report/component/report'),
  loading: Loading,
});

const ApiDoc = Loadable({
  loader: () => import('./modules/apiDoc/component/apiDoc'),
  loading: Loading,
});
const SupportUser = Loadable({
  loader: () => import('./modules/supportUser/component/supportUser'),
  loading: Loading,
});
const SupportUserdetail = Loadable({
  loader: () => import('./modules/supportUser/component/supportUserdetail'),
  loading: Loading,
});

const supportTenant = Loadable({
  loader: () => import('./modules/user/component/supportToken'),
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home', component: MainLayout, allowedRoles: ['OloidSysAdmin'] },
  { path: '/tenants', exact: true, name: 'Home', component: Tenants, allowedRoles: ['OloidSysAdmin'] },
  { path: '/tenants/:id/detail', exact: true, name: 'Home', component: TenantDetail, allowedRoles: ['OloidSysAdmin'] },
  { path: '/report', exact: true, name: 'Report', component: Report, allowedRoles: ['OloidSysAdmin'] },
  { path: '/apiStatus', exact: true, name: 'Home', component: ApiDoc, allowedRoles: ['OloidSysAdmin'] },
  { path: '/supportTenant', exact: true, name: 'Home', component: supportTenant, allowedRoles: ['Support'] },
  { path: '/supportUser', exact: true, name: 'Home', component: SupportUser, allowedRoles: ['OloidSysAdmin'] },
  { path: '/supportUser/:id/detail', exact: true, name: 'Home', component: SupportUserdetail, allowedRoles: ['OloidSysAdmin'] },
];

export default routes;


