import React from "react";
import "./index.scss";
import {
  space,
  color,
  layout,
  flexbox,
  grid,
  background,
  border,
  position,
  shadow,
  system,
} from "styled-system";
import styled from "styled-components";

const fill = system({
  fill: {
    property: "fill",
    scale: "colors",
  },
});
export default function SVGSprite({ icon, className = "", ...rest }) {
  return (
    <svg className={`icon ${icon} ${className}`} {...rest}>
      <use
        xlinkHref={`${process.env.PUBLIC_URL}/images/icons.svg?#${icon}`}
        {...rest}
      />
    </svg>
  );
}

export const StyledSvgSprite = styled(SVGSprite)`
  ${space}
  ${color}
    ${layout}
    ${flexbox}
    ${grid}
    ${background}
    ${border}
    ${position}
    ${shadow}
    ${fill}
`;
