import React, { Fragment, useEffect } from 'react';
import useScript from './useScript';
import { notification as antNotification } from 'antd';

const GoogleSSOLogin = ({
    onGoogleSignIn = () => { },
    onError = () => { },
    text = "Continue with Google",
    autoLogin
}) => {
    const script = useScript('https://accounts.google.com/gsi/client');

    const scriptFlag = '__googleOneTapScript__';
    const handleCredentialResponse = response => {
        console.log(response)
        if (response.credential) {
            // Use Id token here.
            onGoogleSignIn(response.credential);

            window.parent.postMessage(
                {
                    sender: "googleLogin",
                    message: response.credential,
                    token: response.credential
                },
                "*"
            );
        }
    };

    useEffect(() => {
        if (window !== undefined && script === 'ready') {
            window.google.accounts.id.initialize({
                client_id: process.env.REACT_APP_Google_APP_CLIENT_ID,
                callback: data => handleCredentialResponse(data),
               // ux_mode: "redirect"

            });
            window[scriptFlag] = true;
        }
        if (window[scriptFlag] && script === 'ready') {
            window.google.accounts.id.disableAutoSelect()
            // window.google.accounts.id.prompt(notification => {
            //     // Check if google prompt is closed.
            //     if (notification.isSkippedMoment()) {
            //     }
            // });
            var el = document.getElementById('g_id_onload');
            window.google.accounts.id.renderButton(el, {
                type: 'standard',
                shape: 'rectangular',
                theme: 'outline',
                text: 'continue_with',
                size: 'large',
                logo_alignment: 'left',
                width: 275
            });
            if (autoLogin) {
                window.google.accounts.id.prompt(notification => {
                if (notification.isSkippedMoment()) {
                    let message = '';

                    switch (notification.getSkippedReason()) {
                        case 'auto_cancel':
                            message = 'The prompt was automatically canceled by the system.';
                            break;
                        case 'user_cancel':
                            message = 'You have canceled the prompt manually.';
                            break;
                        case 'tap_outside':
                            message = 'You clicked outside the prompt, causing it to close.';
                            break;
                        case 'issuing_failed':
                            message = 'There was an internal issue trying to display the Google prompt.';
                            break;
                        default:
                            message = '';
                            break;
                    }

                    if(message) {
                        antNotification.error({
                           message: 'Error',
                           description: message,
                       });
                    }
                }
            });
            }
        }
    }, [script]);

    return (
        <Fragment>
            {/* <div id='g_id_manual' className='sso-login-box' onClick={promptLogin}>
                <div className='image-logo-container'>
                    <img className='login-icons' src={GoogleIcon} alt="GoogleIcon"></img>
                </div>
                <div className='sso-name'>{text}</div>
            </div> */}
            <div id='g_id_onload' className='sso-login-box google'></div>
        </Fragment>
    );
};

export default GoogleSSOLogin;




