import axios from 'axios';
import { history } from './history';
import _ from "lodash";
import Auth from '@aws-amplify/auth';
import { notification } from 'antd';
import jwtDecode from 'jwt-decode';
import { logout } from '../modules/user/action/user.actions';

/**
 * @returns Unregister callback
 */

const hideShow = (flag) => {
  if (document.getElementById("ui_block")) {
    document.getElementById("ui_block").style.display = flag;
  }
}

const bypassLoader = [
  'reports/devices'
];

export function axiosInterceptors() {
  axios.interceptors.request.use((config) => {

    var list = _.filter(bypassLoader, (res) => { return config.url && config.url.indexOf(res) > -1 });
    if (list.length === 0) {
      hideShow('flex')
    }
    const user = JSON.parse(localStorage.getItem('user') || '{}');

    if(user && user.accesstoken) {
      const expireTime = jwtDecode(user.accesstoken)?.exp;
      const expireTimeDiff = (new Date(expireTime * 1000) - new Date()) / (1000 * 60);
      if (expireTimeDiff <= 1) {
        logout();
        const isUserInLoginPage = window.location.pathname.includes("login");
        if (!isUserInLoginPage) {
          history.push("/login");
        }
        return;
      }
      if (config.url.indexOf("/as/token") === -1) {
        config.headers.common["X-Oloid-Source"] = "Web";
      }

      config.headers.common["Authorization"] = user.accesstoken;
    }

    // return Auth.currentSession()
    //   .then(session => {
    //     config.headers.common['Authorization'] = session.idToken.jwtToken;
    //     return Promise.resolve(config)
    //   }).catch(() => {
    //     return Promise.resolve(config)
    //   })
    
    return config;

  }, (error) => {
    setTimeout(() => {
      hideShow('none')
    }, 500)
    return Promise.reject(error);
  });

  axios.interceptors.response.use((response) => {
    setTimeout(() => {
      hideShow('none')
    }, 500)
    return response;
  }, (error) => {
    setTimeout(() => {
      hideShow('none')
    }, 500)
    if (error.response && 401 === error.response.status) {
      localStorage.clear();
      // if (error.response.data) {
      //   notification.error({
      //     message: 'Error',
      //     className: "toster-error",
      //     style: { width: 400, },
      //     description: error.response.data.message,
      //   })
      // }
      history.push('/login');
    }
    return Promise.reject(error);
  });
}
