import { userConstants } from '../constants';
import { userService } from '../service';
import { setItem, removeItem } from '../../../helpers';
import _ from 'lodash';

export const fetchLoginRequest = request => ({
  type: userConstants.LOGIN_REQUEST,
  payload: {
    loggingIn: true,
    user: request
  }
})

export const fetchLoginSuccess = request => ({
  type: userConstants.LOGIN_SUCCESS,
  payload: { user: { ...request } }
})
export const fetchLoginFailure = error => ({
  type: userConstants.LOGIN_FAILURE,
  payload: {}
})

export const login = (username, password, type) => {
  return dispatch => {
    dispatch(fetchLoginRequest({ username }));
    return userService.login(username, password, type)
      .then(response => {
        response.accesstoken = _.get(response, 'signInUserSession.idToken.jwtToken')
        var user = { username: username, accesstoken: response.accesstoken };
        if (response.attributes) {
          user["email"] = response.attributes["email"];
          //user["role"] = response.attributes["custom:role"];
          user["role"] = 'OloidSysAdmin';
          user["tenantName"] = response.attributes["custom:tenantName"];
          user["tenantID"] = response.attributes["custom:tenantID"];
        }
        setItem('user', user);
        dispatch(fetchLoginSuccess(user));
        return response;;
      },
        error => {
          dispatch(fetchLoginFailure(error));
          return error
        })
  }
}

export const setNewPassword = (user, newPassword) => {
  return dispatch => {
    return userService.setNewPassword(user, newPassword)
      .then(response => {
        response.accesstoken = _.get(response, 'signInUserSession.idToken.jwtToken')
        var userObj = { username: user.username, accesstoken: response.accesstoken };
        if (response.attributes) {
          userObj["email"] = response.attributes["email"];
          //userObj["role"] = response.attributes["custom:role"];
          user["role"] = 'OloidSysAdmin';
          userObj["tenantName"] = response.attributes["custom:tenantName"];
          userObj["tenantID"] = response.attributes["custom:tenantID"];
        }
        setItem('user', userObj);
        dispatch(fetchLoginSuccess(userObj));
        return response;
      },
        error => {
          dispatch(fetchLoginFailure(error));
          return error
        })
  }
}

export const forgotPasswordSubmit = (username, code, new_password) => {
  return dispatch => {
    return userService.forgotPasswordSubmit(username, code, new_password)
      .then(response => {
        return dispatch(login(username, new_password))
          .then((response) => {
            return response;
          })

      },
        error => {
          dispatch(fetchLoginFailure(error));
          return error
        })
  }
}

const parseJwt = (token) => {
  try {
      return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
      return null;
  }
};

export const googleLogin = (token) => {
  return (dispatch) => {
    const decodedJwt = parseJwt(token);
 
    var user = { username: decodedJwt.name, accesstoken: token };
    user["email"] = decodedJwt.email;
    user["role"] = 'Support';
    setItem('user', user);
    dispatch(fetchLoginSuccess(user));
    return user;
  };
};

export const googleTenantLogin = (token) => {
  return async (dispatch) => {
    try {
      const response = await userService.googleLogin(token);
      const idToken = _.get(response, 'data.IdToken', null);
      
      if (idToken) {
        const decodedIdToken = parseJwt(idToken);
        
        const user = {
          accesstoken: idToken,
          email: _.get(decodedIdToken, 'email', ''),
          role: "OloidSysAdmin",
          tenantName: _.get(decodedIdToken, 'custom:tenantName', ''),
          tenantID: _.get(decodedIdToken, 'custom:tenantID', ''),
          username: _.get(decodedIdToken, 'cognito:username', '')
        };

        setItem('user', user);

        dispatch(fetchLoginSuccess(user));
        return response;
      } else {
        throw new Error("ID Token is missing");
      }
    } catch (error) {
      dispatch(fetchLoginFailure(error));
      return error;
    }
  };
};

export const getSupportTenants = (params) => {
  return dispatch => {
    return userService.getSupportTenants(params)
      .then(response => {
        return response;;
      },
        error => {
          error.error = true;
          return error
        })
  }
}

export const getSupportTenantToken = (params) => {
  return dispatch => {
    return userService.getSupportTenantToken(params)
      .then(response => {
        return response;;
      },
        error => {
          error.error = true;
          return error
        })
  }
}


export const userlogout = () => ({
  type: userConstants.LOGOUT,
  payload: {}
})

export const logout = () => {
  return dispatch => {
    userService.logout();
    return dispatch(userlogout());
  }
}